import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { APOLLO_CACHE_PERSIST_KEY } from "~/graphql/client";

export const useClearSiteData = () => {
  const client = useApolloClient();

  return useCallback(() => {
    client.resetStore();
    window.localStorage.removeItem(APOLLO_CACHE_PERSIST_KEY);
  }, [client]);
};
