import type { NextPageContext } from "next";
import Head from "next/head";
import React, { useEffect } from "react";
import styled from "styled-components";

import backgroundImage from "~/assets/images/denim-texture-background.png";
import { AuthenticationWrapper } from "~/components/AuthenticationWrapper";
import { LoginBox } from "~/components/LoginBox";
import { useClearSiteData } from "~/hooks/useClearSiteData";
import { useLocalCohortId } from "~/hooks/useLocalCohortId";
import { getPageTitle } from "~/utils/getPageTitle";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  background-image: url("${backgroundImage.src}");
  background-size: cover;
  display: flex;
  min-height: 100vh;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

function LoginPage({ error }: { error: { message: string | undefined } }) {
  const clearSiteData = useClearSiteData();

  useEffect(() => clearSiteData());
  useLocalCohortId(); // This is a side-effect hook that sets the cohort ID in localStorage
  return (
    <>
      <Head>
        <title>{getPageTitle("Login")}</title>
      </Head>
      <AuthenticationWrapper>
        <LoginBox error={error} />
      </AuthenticationWrapper>
    </>
  );
}

export default LoginPage;

LoginPage.getInitialProps = ({ query }: NextPageContext) => {
  if (query.error) {
    return {
      error: {
        message: `Oops! We encountered an unexpected error trying to authenticate you. Please try again.`,
      },
    };
  }
  return {};
};
